<template>
  <div>
    <c-table
      ref="table"
      title="관련설비 목록"
      tableId="riskHazrd01"
      :columns="grid.columns"
      :data="grid.data"
      :editable="editable&&!param.disabled"
      @linkClick="linkClick"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'moc-facility-restoration-TL',
  components: {
  },
  props: {
    param: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        merge: [
          { index: 0, colName: 'plantNm' }
        ],
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '설비코드',
            align: 'center',
            sortable: true,
            style: 'width:100px',
            type: 'link',
          },
          {
            name: 'col2',
            field: 'col2',
            label: '설비명',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'col3',
            field: 'col3',
            label: '설비유형',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '원복여부',
            align: 'center',
            sortable: true,
            style: 'width:70px',
            type: 'check',
          },
          {
            name: 'col5',
            field: 'col5',
            label: '처리내역',
            align: 'left',
            sortable: true,
            style: 'width:500px',
          },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.grid.data = [
        { col1: '설비코드1', col2: '설비명1', col3: '설비유형1', col4: 'O', col5: '-', },
        { col1: '설비코드2', col2: '설비명2', col3: '설비유형2', col4: 'O', col5: '-', },
        { col1: '설비코드3', col2: '설비명3', col3: '설비유형3', col4: 'O', col5: '-', },
        { col1: '설비코드4', col2: '설비명4', col3: '설비유형4', col4: 'O', col5: '-', }
      ]
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '설비 상세';
      this.popupOptions.param = row;
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
<style lang="sass">
.moc-timeline
  max-height: 550px
  .q-timeline__heading-title
    padding-bottom: 10px
</style>