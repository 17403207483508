var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "관련설비 목록",
          tableId: "riskHazrd01",
          columns: _vm.grid.columns,
          data: _vm.grid.data,
          editable: _vm.editable && !_vm.param.disabled,
        },
        on: { linkClick: _vm.linkClick },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }